import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { List } from 'antd';
import { useGetReportsQuery } from 'entities/report/model/endpoints';
import Spinner from 'shared/ui/Spinner';
import Text from 'shared/ui/Text';
import cl from './style.module.css';
import { Report, ReportsResponse } from 'entities/report/model/schemas';

const Main: FC = () => {
    const navigate = useNavigate();
    const [reports, setReports] = React.useState<Report[] | undefined>(undefined);
    const { data, isLoading, isSuccess } = useGetReportsQuery();

    useEffect(() => {
        if (isSuccess && data) {
            const { redirect, reports } = data as ReportsResponse;

            // Если есть редирект, выполняем его
            if (redirect) {
                navigate(redirect);
            } else {
                // Устанавливаем массив отчетов, если data является массивом отчетов
                if (Array.isArray(data)) {
                    setReports(data);
                } else {
                    console.error("Unexpected data format:", data);
                }
            }
        }
    }, [isSuccess, data, navigate]);

    return (
        <div className={cl.wrapper}>
            {isLoading ? (
                <Spinner size="m" />
            ) : (
                reports && reports.length > 0 ? (
                    <List
                        className={cl.list}
                        size="large"
                        header={<Text variant="m" color="primary" bold>Список отчетов</Text>}
                        bordered
                        dataSource={reports}
                        renderItem={(item) =>
                            <List.Item key={item.id}>
                                <a className={cl.link}  href={`report/${item.id}`}>
                                    <Text variant="s" color="primary">{item.name}</Text>
                                </a>
                            </List.Item>
                    }
                />
                ) : (
                    <Text variant="s" color="primary">Отчеты не доступны</Text>
                )
            )}
        </div>
    );
};

export default Main;

